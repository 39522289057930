"use client";

import { useState, useRef } from "react";
import Link from "next/link";
import Navbar from "./Navbar";

const HeaderLink = ({
  data: { 
    to, title, alwaysVisible, 
    items, truncTitle 
  },
}) => {
  const [visible, setVisible] = useState(false);
  const timeoutRef = useRef(null);

  const onMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, 200);
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setVisible(false);
  };

  return (
    <li
      className={`h-full text-black group
         ${alwaysVisible ? "flex" : "hidden"} 
         md:flex items-center sm:mx-3`
      } 
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        href={to}
        onClick={onMouseLeave}
        className={`
          text-[12px] lg:text-[13px] xl:text-sm desktop:text-base
          text-font-primary text-center 
          group-hover:text-font-brand group-active:text-font-brand
          xs:whitespace-pre whitespace-normal
        `}
      >
        <span
          className={`md:${
            truncTitle?.length > 0 ? "hidden" : ""
          } lg:inline-block`}
        >
          {title}
        </span>
        {truncTitle?.length > 0 && (
          <span className="inline-block lg:hidden">{truncTitle}</span>
        )}
      </Link>

      <div
        className={`${visible ?  "opacity-100 visible" : "opacity-0 invisible"} 
          bg-white absolute top-[40px] md:top-[55px] lg:top-[65px] desktop:top-[75px] 
          left-0 w-full delay-150 transition duration-150 ease-in-out pt-5`
        }
      >
        <Navbar data={items} visible={visible} handleClick={onMouseLeave} />
      </div>
    </li>
  );
};

export default HeaderLink;
