"use client";

import { useState } from "react";
import Link from "next/link";

const Announcer = () => {
  const [visible, setVisible] = useState(false);
  const hideAnnouncer = () => setVisible(false);
  if (!visible) return null;

  return (
    <div className="hidden md:flex items-center justify-center h-[27px] w-full bg-[#44d4d5bf]">
      <Link
        className="text-xs lg:text-[12px] text-[#393939] capitalize flex-1 text-center"
        href="#"
      >
        <span> - new! tinted butter balms just dropped - </span>
      </Link>

      <button
        aria-label="close announcer"
        className="h-full w-[40px] flex items-center justify-center"
        type="button"
        onClick={hideAnnouncer}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
        >
          <mask
            id="mask0_1480_272"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={16}
            height={16}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.43468 7.99995L3.71735 4.28262L4.28268 3.71729L8.00001 7.43462L11.7173 3.71729L12.2827 4.28262L8.56668 7.99995L12.2833 11.7173L11.718 12.2826L8.00001 8.56662L4.28268 12.2833L3.71735 11.718L7.43468 7.99995Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0_1480_272)">
            <rect width={16} height={16} fill="#393939" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.43468 7.99995L3.71735 4.28262L4.28268 3.71729L8.00001 7.43462L11.7173 3.71729L12.2827 4.28262L8.56668 7.99995L12.2833 11.7173L11.718 12.2826L8.00001 8.56662L4.28268 12.2833L3.71735 11.718L7.43468 7.99995Z"
              fill="black"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default Announcer;
