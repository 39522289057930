import Link from "next/link";
import Currency from "./Currency";
import WishlistPopup from "./WishlistPopup";
import Search from "./Search";
import Count from "./Count";

const HeaderRight = () => {
  return (
    <div className="flex justify-end md:justify-start flex-1 h-full pr-3 md:pr-0"
    >
      <div
        style={{ 
          display: 'flex', alignItems: "center", overflow: "hidden",
          maxWidth: "100%", minWidth: "45%"
        }}
      >
        <Currency />

        <WishlistPopup />

        <Link
          className="
            items-center justify-center cursor-pointer 
            hidden md:flex mx-3
          "
          href="/profile"
          aria-label="profile"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            viewBox="0 0 21 21"
            fill="none"
            className="header-svg desktop:min-w-[30px] desktop:min-h-[30px]"
          >
            <mask
              id="mask0_1480_11099"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={21}
              height={21}
            >
              <g clipPath="url(#clip0_1480_11099)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="
                  M10.7666 0.470251C9.74655 0.520826 8.76601 0.880685 7.95538 
                  1.50199C7.39934 1.92473 6.84786 2.59947 6.51734 3.25869C6.06653 
                  4.16583 5.89487 5.1863 6.02408 6.19101C6.15328 7.19572 6.57754 
                  8.13955 7.24321 8.90312C7.96451 9.72851 8.91134 10.2773 10.0316 
                  10.5183C10.3156 10.5795 10.4635 10.5904 10.9903 10.5904C11.5172 
                  10.5904 11.6651 10.5795 11.949 10.5183C12.81 10.3329 13.5167 
                  9.99512 14.1632 9.46008C14.9548 8.79933 15.5281 7.91457 15.8078 
                  6.92203C16.0874 5.92949 16.0603 4.87557 15.7299 3.89873C15.345 
                  2.77683 14.5853 1.82177 13.5788 1.19429C13.1254 0.926341 12.6359 
                  0.724934 12.1252 0.596251C11.6792 0.498982 11.223 0.456668 10.7666 
                  0.470251ZM11.5756 1.40886C12.1901 1.50199 12.9214 1.80421 13.4044 
                  2.16577C15.8121 3.96629 15.6094 7.64221 13.02 9.16425C11.9673 9.78238 
                  10.6306 9.87551 9.47103 9.40986C7.4039 8.58173 6.37216 6.21695 
                  7.14277 4.07495C7.40299 3.35182 7.96725 2.60586 8.61003 2.13838C8.89034 
                  1.93386 9.5249 1.6216 9.8399 1.53303C10.3795 1.38147 11.067 1.33216 
                  11.5756 1.40886ZM5.94303 12.7215C3.86586 12.9214 2.09547 14.4499 1.57686 
                  16.4896C1.44173 17.0219 1.43077 17.1753 1.41342 18.6727C1.3979 19.9984 
                  1.40155 20.1509 1.45086 20.2796C1.51751 20.454 1.63986 20.5252 1.87269 
                  20.5252C2.05712 20.5252 2.16851 20.4696 2.25799 20.3335C2.30912 20.255 
                  2.31551 20.1043 2.33286 18.68C2.35386 16.9936 2.35569 16.9772 2.54195 
                  16.4102C2.78116 15.6852 3.32077 14.9274 3.9316 14.4581C4.58899 13.9513 
                  5.30755 13.6811 6.17038 13.6135C6.6899 13.5733 15.2908 13.5733 15.8103 
                  13.6135C16.8667 13.6957 17.724 14.0883 18.4545 14.8233C19.0014 15.3739 
                  19.3839 16.0778 19.5757 16.8895C19.6195 17.0739 19.6314 17.3826 19.6478 
                  18.68C19.6652 20.1043 19.6716 20.255 19.7227 20.3335C19.8122 20.4696 
                  19.9236 20.5252 20.108 20.5252C20.3408 20.5252 20.4632 20.454 20.5298 
                  20.2796C20.5791 20.1509 20.5828 19.9984 20.5672 18.6727C20.5499 17.1753 
                  20.5389 17.0219 20.4038 16.4896C20.0386 15.0543 19.0133 13.8062 17.6857 
                  13.1798C17.154 12.9243 16.5799 12.7686 15.992 12.7206C15.572 12.6804 
                  6.35755 12.6813 5.94303 12.7215Z"
                  fill="black"
                />
              </g>
            </mask>
            <g mask="url(#mask0_1480_11099)">
              <rect
                width={20}
                height={21}
                transform="translate(0.990234)"
                fill="#393939"
              />
            </g>
            <defs>
              <clipPath id="clip0_1480_11099">
                <rect
                  width="19.1739"
                  height={21}
                  fill="white"
                  transform="translate(1.40332)"
                />
              </clipPath>
            </defs>
          </svg>
        </Link>

        <Search  />

        <Link
          className="
            flex items-center justify-center cursor-pointer relative mx-3
          "
          href="/cart"
          aria-label="cart"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={22}
            viewBox="0 0 20 24"
            fill="none"
            className="header-svg desktop:min-w-[30px] desktop:min-h-[30px]"
          >
            <mask
              id="mask0_1480_11114"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={20}
              height={24}
            >
              <g clipPath="url(#clip0_1480_11114)">
                <mask
                  id="mask1_1480_11114"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={20}
                  height={24}
                >
                  <path
                    d="M0.990234 0.5H19.9902V23.5H0.990234V0.5Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask1_1480_11114)">
                  <path
                    d="
                      M5.87046 11.35V6.17C5.87046 3.32 7.94046 1 10.4905 
                      1C13.0405 1 15.1005 3.32 15.1005 6.18V11.36M3.80046 
                      23H17.1805C18.5405 23 19.6105 21.69 19.4805 20.17L18.6005 
                      9.82C18.4905 8.49 17.4905 7.48 16.3005 7.48H4.68046C3.49046 
                      7.48 2.50046 8.49 2.38046 9.82L1.50046 20.17C1.37046 21.69 
                      2.44046 23 3.80046 23Z
                    "
                    stroke="#393939"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </mask>
            <g mask="url(#mask0_1480_11114)">
              <rect
                width={19}
                height={23}
                transform="translate(0.990234 0.5)"
                fill="#393939"
              />
            </g>
            <defs>
              <clipPath id="clip0_1480_11114">
                <rect
                  width={19}
                  height={23}
                  fill="white"
                  transform="translate(0.990234 0.5)"
                />
              </clipPath>
              <clipPath id="clip1_1480_11114">
                <rect
                  width={19}
                  height={23}
                  fill="white"
                  transform="translate(0.990234 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <Count id={"cartCount"} />
        </Link>
      </div>
      
    </div>
  );
};

export default HeaderRight;
