import Label from "./Label";
import EmailForm from "./EmailForm";

const NewsLetter = () => {
  return (
    <div className="w-1/2 md:w-1/3 align-top mb-[40px]">
      <section className="flex justify-center md:justify-start">
        <ul className="p-[10px] w-[300px]">
          <li className="uppercase my-[5px] w-full">
            <h4
              className="
                text-sm xl:text-base text-font-primaryLight 
                pb-[6px] uppercase
              "
            >
              FOR NEWSLETTERS
            </h4>
          </li>

          <Label />

          <li className="capitalize my-[5px] w-full">
            <EmailForm />
          </li>

          <li className="capitalize my-[5px] w-full">
            <ul className="flex justify-between mt-[10px] w-[100%] md:w-[85%]">
              <li className="w-[30px] h-[30px] md:w-[38px] md:h-[38px]">
                <a
                  target="_blank"
                  rel="noopener"
                  title="Ornaz Instagram"
                  href="https://www.instagram.com/ornaz_com/"
                  className="
                    flex justify-center items-center 
                    mt-[10px] w-[24px] h-[24px] cursor-pointer
                  "

                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={23}
                    height={23}
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      d="M16.4893 0.757324H6.50488C5.41113 0.757324 4.40723 1.02686 3.49316 1.56592C2.5791 
                      2.10498 1.85254 2.83154 1.31348 3.74561C0.774414 4.65967 0.504883 5.66357 0.504883 
                      6.75732V16.7417C0.504883 17.8354 0.774414 18.8394 1.31348 19.7534C1.85254 20.6675 
                      2.5791 21.394 3.49316 21.9331C4.40723 22.4722 5.41113 22.7417 6.50488 
                      22.7417H16.4893C17.583 22.7417 18.5869 22.4722 19.501 21.9331C20.415 21.394 
                      21.1416 20.6675 21.6807 19.7534C22.2197 18.8394 22.4893 17.8354 22.4893 
                      16.7417V6.75732C22.4893 5.66357 22.2197 4.65967 21.6807 3.74561C21.1416 2.83154 
                      20.415 2.10498 19.501 1.56592C18.5869 1.02686 17.583 0.757324 16.4893 
                      0.757324ZM20.4971 16.7417C20.4971 17.4604 20.3174 18.1284 19.958 18.7456C19.5986 
                      19.3628 19.1104 19.8511 18.4932 20.2104C17.876 20.5698 17.208 20.7495 16.4893 
                      20.7495H6.50488C5.78613 20.7495 5.11816 20.5698 4.50098 20.2104C3.88379 19.8511 
                      3.39551 19.3628 3.03613 18.7456C2.67676 18.1284 2.49707 17.4604 2.49707 
                      16.7417V6.75732C2.49707 6.03857 2.67676 5.37061 3.03613 4.75342C3.39551 4.13623 
                      3.88379 3.64795 4.50098 3.28857C5.11816 2.9292 5.78613 2.74951 6.50488 
                      2.74951H16.4893C17.208 2.74951 17.876 2.9292 18.4932 3.28857C19.1104 3.64795 
                      19.5986 4.13623 19.958 4.75342C20.3174 5.37061 20.4971 6.03857 20.4971 
                      6.75732V16.7417ZM12.2939 6.75732C11.7939 6.64795 11.2939 6.64795 10.7939 
                      6.75732C9.91895 6.88232 9.13379 7.22607 8.43848 7.78857C7.74316 8.35107 7.23145 
                      9.04639 6.90332 9.87451C6.5752 10.7026 6.47363 11.562 6.59863 12.4526C6.69238 
                      13.1089 6.91113 13.7222 7.25488 14.2925C7.59863 14.8628 8.04395 15.3511 8.59082 
                      15.7573C9.59082 16.4136 10.5908 16.7417 11.5908 16.7417C11.9346 16.7417 12.1689 
                      16.7104 12.2939 16.6479C12.9502 16.5542 13.5674 16.3354 14.1455 15.9917C14.7236 
                      15.6479 15.208 15.2026 15.5986 14.6558C15.9893 14.1089 16.2627 13.519 16.4189 
                      12.8862C16.5752 12.2534 16.5986 11.6089 16.4893 10.9526C16.3486 9.84326 15.8916 
                      8.90186 15.1182 8.12842C14.3447 7.35498 13.4033 6.89795 12.2939 6.75732ZM14.0049 
                      13.4604C13.7393 13.7729 13.4424 14.0308 13.1143 14.2339C12.7549 14.4683 12.376 
                      14.6089 11.9775 14.6558C11.5791 14.7026 11.1885 14.6753 10.8057 14.5737C10.4229 
                      14.4722 10.0791 14.2925 9.77441 14.0347C9.46973 13.7769 9.21582 13.4839 9.0127 
                      13.1558C8.77832 12.7964 8.6377 12.4292 8.59082 12.0542C8.49707 11.5542 8.55176 
                      11.062 8.75488 10.5776C8.95801 10.0933 9.27051 9.68311 9.69238 9.34717C10.1143 
                      9.01123 10.583 8.81201 11.0986 8.74951H11.8955C12.5518 8.84326 13.1064 9.12061 
                      13.5596 9.58154C14.0127 10.0425 14.2939 10.6011 14.4033 11.2573C14.4971 11.6323 
                      14.5049 12.0112 14.4268 12.394C14.3486 12.7769 14.208 13.1323 14.0049 13.4604ZM17.708 
                      5.53857C17.5049 5.35107 17.2705 5.25732 17.0049 5.25732C16.7393 5.25732 16.5049 5.35498 
                      16.3018 5.55029C16.0986 5.74561 15.9971 5.97607 15.9971 6.2417C15.9971 6.50732 16.0986 
                      6.7417 16.3018 6.94482C16.5049 7.14795 16.7393 7.24951 17.0049 7.24951C17.1924 7.24951 
                      17.3252 7.21826 17.4033 7.15576C17.4658 7.15576 17.5674 7.08545 17.708 6.94482C17.8955 
                      6.7417 17.9893 6.50732 17.9893 6.2417C17.9893 6.0542 17.958 5.92139 17.8955 
                      5.84326C17.8955 5.78076 17.833 5.6792 17.708 5.53857Z"
                      fill="#333333"
                    />
                  </svg>
                </a>
              </li>

              <li className="w-[30px] h-[30px] md:w-[38px] md:h-[38px]">
                <a
                  target="_blank"
                  rel="noopener"
                  title="Ornaz YouTube"
                  href="https://www.youtube.com/@ornaz_com/"
                  className="
                    flex justify-center items-center 
                    mt-[10px] w-[24px] h-[24px] cursor-pointer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={25}
                    height={18}
                    viewBox="0 0 25 18"
                    fill="none"
                  >
                    <path
                      d="M24.4104 3.88525C24.3792 3.729 24.3479 3.58057 24.3167 3.43994C24.2385 3.08057 24.137 2.75244 24.012 2.45557C23.8557 2.03369 23.6682 1.72119 23.4495 1.51807C23.0901 1.14307 22.676 0.869629 22.2073 0.697754C21.9417 0.604004 21.6057 0.541504 21.1995 0.510254L21.0354 0.486816C19.6448 0.393066 17.8323 0.322754 15.5979 0.275879L12.6448 0.252441L9.69165 0.275879C7.45728 0.322754 5.64478 0.393066 4.25415 0.486816L4.09009 0.510254C3.68384 0.541504 3.3479 0.604004 3.08228 0.697754C2.61353 0.869629 2.19946 1.14307 1.84009 1.51807C1.62134 1.72119 1.43384 2.03369 1.27759 2.45557C1.15259 2.75244 1.05103 3.08057 0.9729 3.43994L0.87915 3.88525L0.761963 5.104C0.683838 6.07275 0.644775 6.96338 0.644775 7.77588V9.604C0.644775 10.4009 0.683838 11.2915 0.761963 12.2759C0.808838 12.7603 0.8479 13.1587 0.87915 13.4712L0.9729 13.9399C1.05103 14.2993 1.15259 14.6274 1.27759 14.9243C1.43384 15.3306 1.62134 15.6431 1.84009 15.8618C2.16821 16.2056 2.57446 16.4556 3.05884 16.6118C3.32446 16.7056 3.69165 16.7837 4.1604 16.8462L4.48853 16.8931C5.2854 16.9712 6.88696 17.0337 9.29321 17.0806L12.6448 17.1274L15.5979 17.104C17.8323 17.0571 19.6526 16.979 21.0588 16.8696H21.1995C21.6057 16.8228 21.9417 16.7524 22.2073 16.6587C22.676 16.5024 23.0901 16.2368 23.4495 15.8618C23.6682 15.6431 23.8557 15.3306 24.012 14.9243C24.137 14.6274 24.2385 14.2993 24.3167 13.9399L24.4104 13.4712L24.5276 12.2759C24.6057 11.3071 24.6448 10.4165 24.6448 9.604V7.77588C24.6448 6.96338 24.6057 6.07275 24.5276 5.104C24.4807 4.61963 24.4417 4.21338 24.4104 3.88525ZM10.1604 11.8071V5.05713L16.6526 8.43213L10.1604 11.8071Z"
                      fill="#333333"
                    />
                  </svg>
                </a>
              </li>

              <li className="w-[30px] h-[30px] md:w-[38px] md:h-[38px]">
                <a
                  target="_blank"
                  rel="noopener"
                  title="Ornaz Facebook"
                  href="https://www.facebook.com/ornazjewellery/"
                  className="
                    flex justify-center items-center mt-[10px] 
                    w-[24px] h-[24px] cursor-pointer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={22}
                    viewBox="0 0 12 22"
                    fill="none"
                  >
                    <path
                      d="M11.4033 0.437012V3.76514H9.43457C8.7002 3.76514 8.20801 3.91748 7.95801 
                      4.22217C7.70801 4.52686 7.58301 4.97607 7.58301 5.56982V7.96045H11.2861L10.7939 
                      11.7104H7.58301V21.2964H3.71582V11.7104H0.504883V7.96045H3.71582V5.21826C3.71582 
                      3.65576 4.15332 2.44092 5.02832 1.57373C5.90332 0.706543 7.0752 0.272949 8.54395 
                      0.272949C9.77832 0.272949 10.7314 0.327637 11.4033 0.437012Z"
                      fill="#333333"
                    />
                  </svg>
                </a>
              </li>

              <li className="w-[30px] h-[30px] md:w-[38px] md:h-[38px]">
                <a
                  target="_blank"
                  rel="noopener"
                  title="Ornaz Twitter"
                  href="https://twitter.com/ORNAZ_com/"
                  className="
                    flex justify-center items-center mt-[10px] 
                    w-[24px] h-[24px] cursor-pointer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={25}
                    height={22}
                    viewBox="0 0 25 22"
                    fill="none"
                  >
                    <path
                      d="M23.8486 1.37447C23.708 1.43697 23.5674 1.49947 23.4268 1.56197C23.1143 
                      1.68697 22.8564 1.78853 22.6533 1.86666C22.3252 1.99166 21.9424 2.11666 
                      21.5049 2.24166L21.2002 1.91353C20.9502 1.66353 20.6689 1.43697 20.3564 
                      1.23384C19.9346 0.952595 19.4893 0.733845 19.0205 0.577595C18.4424 0.390095 
                      17.8564 0.31197 17.2627 0.34322C16.5752 0.390095 15.8799 0.59322 15.1768 
                      0.952595C14.0674 1.49947 13.2939 2.30416 12.8564 3.36666C12.5439 4.13228 
                      12.4111 4.97603 12.458 5.89791C12.4893 6.36666 12.5518 6.75728 12.6455 
                      7.06978H12.083C11.5986 7.03853 11.0908 6.97603 10.5596 6.88228C9.8252 
                      6.75728 9.08301 6.58541 8.33301 6.36666C7.41113 6.06978 6.5166 5.70259 5.64941 
                      5.26509C4.78223 4.82759 4.00488 4.36666 3.31738 3.88228C2.77051 3.49166 2.28613 
                      3.10103 1.86426 2.71041C1.58301 2.42916 1.34082 2.16353 1.1377 1.91353L0.950195 
                      1.60884L0.786133 1.93697C0.692383 2.24947 0.62207 2.58541 0.575195 2.94478C0.52832 
                      3.46041 0.540039 3.99947 0.610352 4.56197C0.680664 5.12447 0.895508 5.78853 1.25488 
                      6.55416C1.52051 7.10103 1.83301 7.66353 2.19238 8.24166L2.68457 8.94478L0.504883 
                      8.07759L0.551758 8.42916C0.614258 8.74166 0.708008 9.04634 0.833008 9.34322C0.989258 
                      9.78072 1.2002 10.1792 1.46582 10.5385C1.80957 11.0698 2.55176 11.7026 3.69238 
                      12.437C4.25488 12.812 4.78613 13.1323 5.28613 13.3979L2.4502 13.4213L2.54395 
                      13.6088C2.65332 13.7807 2.78613 13.9682 2.94238 14.1713C3.16113 14.437 3.42676 
                      14.7182 3.73926 15.0151C4.16113 15.437 4.93457 15.8745 6.05957 16.3276C6.6377 
                      16.562 7.16113 16.7573 7.62988 16.9135L7.06738 17.2885C6.59863 17.601 6.15332 
                      17.8745 5.73145 18.1088C5.1377 18.4526 4.62988 18.7026 4.20801 18.8588C3.62988 
                      19.0776 2.85645 19.2573 1.8877 19.3979C1.40332 19.4604 0.973633 19.4995 0.598633 
                      19.5151L0.99707 19.726C1.3252 19.8979 1.68457 20.062 2.0752 20.2182C2.6377 20.4213 
                      3.21582 20.5932 3.80957 20.7338C4.5752 20.9213 5.34863 21.0463 6.12988 21.1088C7.05176 
                      21.187 7.98145 21.1792 8.91895 21.0854C11.3721 20.8354 13.5283 20.1323 15.3877 
                      18.976C17.1533 17.8667 18.7236 16.3042 20.0986 14.2885C20.8955 13.1167 21.5049 
                      11.7495 21.9268 10.187C22.2236 9.06197 22.4111 7.89791 22.4893 6.69478C22.5361 
                      6.10103 22.5518 5.60884 22.5361 5.21822L22.7705 4.98384C22.9893 4.74947 23.2471 
                      4.49556 23.5439 4.22213C23.8408 3.94869 24.083 3.71041 24.2705 3.50728L24.458 
                      3.24947L22.6768 3.43697L23.8486 1.37447Z"
                      fill="#333333"
                    />
                  </svg>
                </a>
              </li>

              <li className="w-[30px] h-[30px] md:w-[38px] md:h-[38px]">
                <a
                  target="_blank"
                  rel="noopener"
                  title="Ornaz LinkedIn"
                  href="https://www.linkedin.com/company/ornaz/"
                  className="
                    flex justify-center items-center mt-[10px] 
                    w-[24px] h-[24px] cursor-pointer
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M19.7891 0.749512H2.25781C1.77344 0.749512 1.35938 0.921387 1.01562 
                      1.26514C0.671875 1.60889 0.5 2.02295 0.5 2.50732V20.0854C0.5 20.5698 0.671875 
                      20.9839 1.01562 21.3276C1.35938 21.6714 1.77344 21.8433 2.25781 
                      21.8433H19.7891C20.2734 21.8433 20.6875 21.6714 21.0312 21.3276C21.375 
                      20.9839 21.5469 20.5698 21.5469 20.0854V2.50732C21.5469 2.02295 21.375 
                      1.60889 21.0312 1.26514C20.6875 0.921387 20.2734 0.749512 19.7891 
                      0.749512ZM6.73438 18.1401H3.61719V8.78857H6.73438V18.1401ZM5.16406 
                      7.66357C4.67969 7.66357 4.26562 7.4917 3.92188 7.14795C3.57812 6.8042 
                      3.40625 6.38623 3.40625 5.89404C3.40625 5.40186 3.57812 4.98389 3.92188 
                      4.64014C4.26562 4.29639 4.68359 4.12451 5.17578 4.12451C5.66797 4.12451 
                      6.08594 4.29639 6.42969 4.64014C6.77344 4.98389 6.94531 5.40186 6.94531 
                      5.89404C6.94531 6.38623 6.77344 6.8042 6.42969 7.14795C6.08594 7.4917 
                      5.66406 7.66357 5.16406 7.66357ZM18.4531 18.1401H15.3125L15.2422 
                      12.5854V12.3511C15.2109 12.1792 15.1562 12.0151 15.0781 11.8589C14.9688 
                      11.6401 14.8203 11.4683 14.6328 11.3433C14.3828 11.2026 14.0742 11.1323 13.707 
                      11.1323C13.3398 11.1323 13.0156 11.2104 12.7344 11.3667C12.5156 11.4917 
                      12.3359 11.6558 12.1953 11.8589C12.0859 12.0151 12 12.187 11.9375 
                      12.3745L11.8672 12.5854V18.1401H8.72656V8.78857H11.75V10.0776L11.7969 
                      9.96045C11.8594 9.85107 11.9375 9.7417 12.0312 9.63232C12.1719 9.47607 
                      12.3438 9.31982 12.5469 9.16357C12.8594 8.94482 13.2812 8.78857 13.8125 
                      8.69482C14.2031 8.6167 14.5625 8.57764 14.8906 8.57764L15.0781 8.60107C15.4219 
                      8.60107 15.7109 8.63232 15.9453 8.69482C16.3359 8.78857 16.7578 8.9917 
                      17.2109 9.3042C17.4766 9.4917 17.7109 9.76514 17.9141 10.1245C18.0547 
                      10.4058 18.1719 10.7417 18.2656 11.1323C18.3438 11.3979 18.3984 11.6714 
                      18.4297 11.9526L18.4531 12.3276V18.1401Z"
                      fill="#333333"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default NewsLetter;
