"use client";

import { useSelector } from "react-redux";

function formatCurrency(num) {
    return num >= 1e6 ? (num / 1e6).toFixed(1) + 'M' : num >= 1e3 ? (num % 1e3 ? (num / 1e3).toFixed(1) : (num / 1e3)) + 'K' : num.toString();
}

const PriceConvert = ({ amount }) => {
  const currencyMeta = useSelector((state) => state.general.currencyMeta);
  const num =  Math.floor(Number(amount) * currencyMeta.rate);
  return formatCurrency(num);
};

export default PriceConvert;