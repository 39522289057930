"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { getLoggingPayload } from "@/lib/utils";
import { loggingAPI } from "@/clientSideApi/core";

const Logging = () => {

  const pathname = usePathname();

  useEffect(() => {
    const loggingTimeout = setTimeout(() => {
      loggingAPI(getLoggingPayload(pathname));
    }, 2000);
    return () => clearTimeout(loggingTimeout);
  }, [pathname]);

  return null;
};

export default Logging;
