"use client";
import dynamic from "next/dynamic";

import { useState, useTransition, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import currencyJSON from "./data.json";
import { symbolData } from "@/constants/symbol";
import { setCurrencyAPI } from "@/clientSideApi/core";
const Container = dynamic(() => import("./Container"), { ssr: false });

const Currency = () => {
  const cCode = useSelector((state) => state.general.currencyMeta.code);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currencyData, setCurrencyData] = useState(currencyJSON);
  const [isPending, startTransition] = useTransition();

  const currentSymbol = symbolData[cCode];
  const charlen = currentSymbol.length;

  const onOpenChange = (val) => {
    setVisible(val);
  };

  const updateData = useCallback(
    (val) => {
      if (isPending) return;
      if (val.length < 3) {
        setCurrencyData(currencyJSON);
        return;
      }
      const data = currencyJSON.filter(
        (ele) =>
          ele.name.toLowerCase().includes(val.toLowerCase()) ||
          ele.code.toLowerCase().includes(val.toLowerCase()) ||
          ele.symbol.toLowerCase().includes(val.toLowerCase())
      );

      setCurrencyData(data);
    },
    [isPending]
  );

  const handleChangeText = useCallback(
    (e) => {
      const val = e.target.value;
      setSearch(val);
      startTransition(() => updateData(val));
    },
    [updateData]
  );

  const handlePress = useCallback(
    async (currencyInfo) => {
      if (loading) return;
      if (currencyInfo.code !== cCode) {
        setLoading(true);
        await setCurrencyAPI({ currency_code: currencyInfo.code });
        setLoading(false);
      }
      onOpenChange(false);
    },
    [loading, cCode]
  );

  return (
    <DropdownMenu
      open={visible}
      onOpenChange={onOpenChange}
    >
      <DropdownMenuTrigger asChild id="">
        <Button
          aria-label={cCode}
          variant="secondary"
          className="
                bg-white hover:bg-white
                px-0 py-0 mx-3 hidden md:flex 
                hover:text-font-brand
                text-font-primary group
                hover:text-font-brand
            "
        >
          <span
            className="
              text-xs md:text-[13px] 
              base pr-[4px] capitalize
            "
          >
            {cCode}
          </span>

          <div
            className={`
              flex items-center justify-center 
              border-[0.5px] border-[#393939] 
              px-[${charlen === 1 ? "3px" : "5px"}] py-[${charlen === 1 ? "1px" : "5px"}]
              group-hover:border-[#45d4d5] rounded-full
              min-h-[23px] min-w-[23px] max-h-[50px] max-w-[50px] 
              desktop:min-w-[30px] desktop:min-h-[30px]
            `}
          >
            <span className={`text-[${charlen === 1 ? 12 : 10}] desktop:text-base`}>
              {currentSymbol}
            </span>
          </div>
        </Button>
      </DropdownMenuTrigger>

      <Container
        loading={loading}
        cCode={cCode}
        search={search}
        currencyData={currencyData}
        handleChangeText={handleChangeText}
        handlePress={handlePress}
        onOpenChange={onOpenChange}
      />
    </DropdownMenu>
  );
};

export default Currency;
