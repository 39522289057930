import CallUs from "./CallUs";
import CustomLink from "@/components/CustomUI/modules/CustomLink";

const Column = ({ data }) => {
  return (
    <div className="w-1/2 md:w-1/3 align-top mb-[20px]">
      <nav className={`flex justify-center ${data.extraClasses}`}>
        <ul className="p-[10px] w-[300px]">
          <li className="uppercase my-[5px] w-full">
            <h3 className="text-sm xl:text-base pb-[6px] uppercase">{data.heading}</h3>
          </li>
          {data.items.map((listItem) =>

            listItem.is_ivr ?
              <CallUs listItem={listItem} key={listItem.to + listItem.title} />
              :
              <li
                className="
                  capitalize lg:my-[5px] xl:my-[8px] desktop:my-[12px] w-full
                  text-font-primaryLight
                "
                key={listItem.title}
              >
                <CustomLink
                  aria-label={listItem.title}
                  href={listItem.to}
                  isNative={listItem.isNative}
                  classes="
                      text-[12px] xl:text-sm desktop:text-base
                      text-font-primaryLight
                      hover:text-font-brand
                    "
                >
                  {listItem.title}
                </CustomLink>
              </li>

          )}
        </ul>
      </nav>
    </div>
  );
};

export default Column;
