import Link from "next/link";

const CustomLink = ({ isNative, target, children, href, classes, handleClick, ...rest }) => {
  if (isNative) {
    return (
      <a href={href} target={target ? target : "_parent"} className={classes} onClick={handleClick && handleClick} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link href={href} className={classes} onClick={handleClick && handleClick} {...rest}>
      {children}
    </Link>
  );
};

export default CustomLink;
