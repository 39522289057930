import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@/lib/utils";

export const wishlistApiSlice = createApi({
  baseQuery: axiosBaseQuery,
  reducerPath: "api2",
  tagTypes: ["Wishlist", "General"],
  endpoints: (builder) => ({
    addWishlistItem: builder.mutation({
      query: (initialPost) => {
        const { isWishlistPage, ...rest } = initialPost;
        return {
        url: "/api/rest/v1/wishlist/add/",
        method: "POST",
        body: rest,
      }},
      invalidatesTags: (result, error, arg) => {
        if (!error && !arg.isWishlistPage) {
          return ["Wishlist"];
        }
        return [];
      },
    }),
    removeWishlistItem: builder.mutation({
      query: (initialPost) => {
        const { isWishlistPage, ...rest } = initialPost;
        return {
          url: "/api/rest/v1/wishlist/delete/",
          method: "POST",
          body: rest,
        }
      },
      invalidatesTags: (result, error, arg) => {
        if (!error && !arg.isWishlistPage) {
          return ["Wishlist"];
        }
        return [];
      },
    }),
    getWishlistProducts: builder.query({
      query: () => "/api/rest/v1/wishlist/products/",
      providesTags: ['General'],
      serializeQueryArgs: () => 'General',
    }),
    getDetailedWishlist: builder.query({
      query: () => "/api/rest/v1/wishlist/",
      providesTags: ['Wishlist'],
      serializeQueryArgs: () => 'DetailedWishlist',
    }),
    invalidateCache: builder.mutation({
      query: () => null,
      invalidatesTags: () => ["Wishlist"]
    }),
  }),
});

export const {
  useAddWishlistItemMutation,
  useRemoveWishlistItemMutation,
  useGetWishlistProductsQuery,
  useGetDetailedWishlistQuery,
} = wishlistApiSlice;
