"use client";
import { usePathname } from "next/navigation";
import { HeaderLeft, HeaderLogo, HeaderRight } from "./includes";

const Header = () => {
  const pathname = usePathname();
  const isHidden = pathname.startsWith('/chat/');

  if(isHidden) return null;

  return (
    <header
      className="
        flex items-center bg-white sticky 
        top-0 w-[100%] h-[45px] md:h-[60px] 
        lg:h-[70px] desktop:h-[80px] select-none z-[49]        
      "
      id="header"
    >
      <HeaderLeft />

      <HeaderLogo />

      <HeaderRight />
    </header>
  );
};

export default Header;
