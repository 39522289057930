"use client";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAddNewsLetterMutation } from "@/store/general/generalApiSlice";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";
import { unsubscribenewsLetterAPI } from "@/clientSideApi/core";
import { setNewsletterStatus } from "@/store/general/generalSlice";

const EmailForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.general.user);
  const isSubbed = user?.is_newsletter_subscribed;

  const [addEmail, { isLoading }] = useAddNewsLetterMutation();

  const handleUnSub = async () => {
    if (loading || !user) return;
    setLoading(true);
    const apiResponse = await unsubscribenewsLetterAPI({ email: user.email });
    setLoading(false);
    if (!apiResponse || apiResponse.error) return;
    dispatch(setNewsletterStatus(false));
  }

  if (isSubbed) {
    return (
      <Button
        aria-label="un-subscribe newsletter"
        variant="primary"
        className="p-0 m-0 h-auto"
        onClick={handleUnSub}
        disabled={loading}
      >
        <p
          className="
              text-[12px] text-font-primaryLight
              underline
            ">
          Unsubscribe
        </p>
      </Button>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    const apiResponse = await addEmail({ email });
    if (!apiResponse || apiResponse.error) return;
    dispatch(setNewsletterStatus(true));
    toast.success("Thank You for joining our Newsletter", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) handleSubmit(e);
  };

  return (
    <form className="mt-[10px]" style={{ display: "flex", flexDirection: 'row' }} onSubmit={handleSubmit}>
      <input
        className="
          p-2 border-[1px] border-[#bdbdbd] outline-0 
          text-font-primaryLight text-[10px] sm:text-[12px] 
          md:text-sm xl:text-base 
          border-r-0 w-full h-[42px] placeholder:text-[10px] 
          placeholder:md:text-[13px] placeholder:xl:text-base
          placeholder:text-gray-700	
        "
        style={{ flex: 1 }}
        placeholder="Your Email Address"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isLoading}
        onKeyDown={handleKeyDown}
      />
      <Button
        disabled={isLoading}
        aria-label="subscribe newsletter"
        variant="primary"
        className="
          flex justify-center items-center w-[45px] 
          bg-[#45d4d5] border-[1px] border-[#3e9f8e] 
          text-white p-[7px] select-none touch-manipulation 
          transition duration-150 ease-in-out hover:drop-shadow-md
          rounded-s-none h-[42px]
        "
        type="submit"
      >
        <div className="h-[25px] w-[25px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="
                M8.79289 6.29289C9.18342 5.90237 9.81658 5.90237 10.2071 
                6.29289L15.2071 11.2929C15.5976 11.6834 15.5976 12.3166 15.2071 
                12.7071L10.2071 17.7071C9.81658 18.0976 9.18342 18.0976 8.79289 
                17.7071C8.40237 17.3166 8.40237 16.6834 8.79289 16.2929L13.0858 
                12L8.79289 7.70711C8.40237 7.31658 8.40237 6.68342 8.79289 6.29289Z
              "
              fill="#fff"
            />
          </svg>
        </div>
      </Button>
    </form>
  );
};

export default EmailForm;
