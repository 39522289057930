"use client";
import { usePathname } from "next/navigation";
import { Column, NewsLetter } from "./includes";
import footerData from "./footerData.json";

const Footer = () => {
  const pathname = usePathname();
  const isHidden = pathname.startsWith('/chat/');

  if (isHidden) return null;

  return (
    <footer
      className="
        px-8 pt-20 border-t-[1px] border-[#d3d3d3] 
        w-full text-font-primaryLight
      "
      id="footer"
    >
      <div className="flex flex-wrap">
        {
          footerData.map(ele => (
            <Column
              key={ele.heading}
              data={ele}
            />
          ))
        }
        <NewsLetter />
      </div>
    </footer>
  );
};

export default Footer;
