"use client";
import { useSelector } from "react-redux";


const Count = ({ id }) => {
  const item = useSelector((state) => state.general.headerInfo[id]);
  if(item <= 0) return null;
  return (
    <div 
      className="
        absolute top-[9px] right-[-4px] 
        md:top-[3px] md:right-[-2px]
        h-[15px] w-[15px] overflow-hidden
        rounded-full bg-[#45d4d5]
        flex items-center justify-center
      "
    >
      <span className="text-font-primary text-[9px] text-white">
        {item}
      </span>
    </div>
  );
};

export default Count;
