"use client";

import Link from "next/link";

const CallUs = ({ listItem }) => {    
    const handleClick = () => {
        gtag('event', 'ivr_select');
    }

    return (
        <li
            className="
                capitalize lg:my-[5px] xl:my-[8px] desktop:my-[12px] w-full
                text-font-primaryLight
            "
            key={listItem.title}
        >
            <Link
                aria-label={listItem.title}
                href={listItem.to}
                className="
                    text-[12px] xl:text-sm desktop:text-base
                    text-font-primaryLight
                    hover:text-font-brand
                "
                onClick={handleClick}
            >
                {listItem.title}
            </Link>
        </li>
    )
}

export default CallUs