
import { configureStore } from "@reduxjs/toolkit";
import generalSlice from "./general/generalSlice";
import { generalApiSlice } from "./general/generalApiSlice";

import catalogSlice from "./catalog/catalogSlice";
import pdpSlice from "./pdp/pdpSlice";

import wishlistSlice from "./wishlist/wishlistSlice";
import { wishlistApiSlice } from "./wishlist/wishlistApiSlice";
import { ordersApiSlice } from "./orders/ordersApiSlice";

import cartSlice from "./cart/cartSlice";

import { injectStore } from "@/clientSideApi";

export const store = configureStore({
  reducer: {
    general: generalSlice,
    catalog: catalogSlice,
    pdp: pdpSlice,
    wishlist: wishlistSlice,
    cart: cartSlice,
    [generalApiSlice.reducerPath]: generalApiSlice.reducer,
    [wishlistApiSlice.reducerPath]: wishlistApiSlice.reducer,
    [ordersApiSlice.reducerPath]: ordersApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: false, serializableCheck: false})
    .concat(generalApiSlice.middleware)
    .concat(wishlistApiSlice.middleware)
    .concat(ordersApiSlice.middleware)
});


injectStore(store);
