import Link from "next/link";

const HeaderLogo = () => {
  return (
    <div className="flex flex-1 items-center justify-center h-full">
      <Link href="/" className="select-none" aria-label="ORNAZ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 778 190.4"
          fillRule="evenodd"
          fill="black"
          className="
            max-h-[45px] md:max-h-[60px] lg:max-h-[70px] desktop:max-h-[80px]
            w-full max-w-[17vw] md:max-w-[10vw] lg:max-w-[9vw] 
          "
          style={{
            minWidth: "100px",
            minHeight: "40px"
          }}
          width={"9vw"}
        >
          <path d="
            M116.4 68.4c16.4 0 27.4 3.9 37.3 13.7 32.7 32.7 13 90.3-36.3 90.3-52.7 
            0-71.1-74.7-22-99a53.03 53.03 0 0 1 21-5zm-67 52c0 17.3 5.8 31.1 15.7 
            43.3 1.2 1.8 2.6 3.4 4 5 41.4 41.4 118.3 14.5 118.3-48.3 0-59.6-74.5-91.3-117.2-48.2C59 
            83.4 49.5 98.4 49.4 120.4h0zm313 65h17v-95c3.2.8 4.7 2.8 6.7 5.3l66.5 69.5c2 2 3.9 4.1 
            5.6 6.4l19.2 18.9v-135h-17v94c-3.8-2.6-75.8-78.1-84.7-88.2-2.6-3-9.8-10.8-13.3-11.7v135.8zm-101-69h-8v-46c11.4 
            0 29.2.9 35.5 10.5 7.3 11.3 6.4 35.5-27.5 35.5zm-25 69h17v-52c0-4.6 5.7-2.5 6.7-1.8l32.1 
            46c2.1 3.2 3.4 4.4 5.2 7.8h21l-25.1-34.9c-1.9-2.5-3.2-4.2-5.1-6.9-3.5-5.2-9.5-11.1-9.8-15.2 35.8 0 
            48.2-73-12-73h-30v130zm347-98l22 50h-45l19.2-43.7c1.6-3.8.8-4.1 3.8-6.3zm-63 98h18l10.7-23.3c1.7-3.5 
            2.4-4.7 3.3-8.7h60c5.4 10.2 9.5 21.7 15 32h18l-31-68c-2.8-5.6-5.3-11.5-8-17l-21.2-46.8c-1.5-2.7-.6-2.4-3.8-3.2-.4 
            4.9-12.4 28.2-15 34s-5.2 10.7-7.6 16.4c-1.5 3.8-2.1 4.8-3.8 8.2l-27 59c-1.5 3.3-2.4 4.8-3.8 8.2-1.2 
            3.2-3 5.8-3.8 9.2h0zm168-115c8 1.9 48 1.9 56 0-1.3 5.6-63.5 109.2-65 115h93c0-20.2 5-16-31-16-8 
            0-28.7-1.5-34 1 .2-3.3 5.4-11.2 7.4-14.6l57.5-100.4h-84l.1 15zM5.5 51.4c13.1-1.1 26.7-2 
            41-2l1-21c0-7.5 1-12.5 1-21-3.5.1-14.7 1.7-17.3 3.7-3.1 2.4-7.9 8-10.9 11-2.9 2.9-8.9 8.2-10.9 
            11.1-2.6 3.4-3.9 12.4-3.9 18.2z" 
          />
        </svg>
      </Link>
    </div>
  );
};

export default HeaderLogo;
