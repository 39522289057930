"use client";

import { useRef, useEffect } from 'react';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const HeaderScroll = () => {
  //FIXME: problem in safari <====
  const prevScrollPos = useRef(0);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const header = document.getElementById("header");
    const searchBar = document.getElementById("searchBar");
    if (!header) return;
    if (prevScrollPos.current <= 0 || prevScrollPos.current > currentScrollPos) {
      header.classList.remove('customFaded');
      if (searchBar) {
        searchBar.classList.remove("top-[-45px]", "md:top-[-60px]", "lg:top-[-70px]")
      }
    } else {
      header.classList.add('customFaded');
      if (searchBar) {
        searchBar.classList.add("top-[-45px]", "md:top-[-60px]", "lg:top-[-70px]")
      }
    }

    prevScrollPos.current = currentScrollPos;
  };

  const debouncedHandleScroll = debounce(handleScroll, 20);

  useEffect(() => {
    const scrollListener = () => debouncedHandleScroll();
    window.addEventListener('scroll', scrollListener, { passive: true });

    return () => {
      window.removeEventListener('scroll', scrollListener, { passive: true });
    };
  }, [debouncedHandleScroll]);

  return null;
};

export default HeaderScroll;