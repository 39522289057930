import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { 
    wishlist_lines: []
  },
  items: [],
  loaderID: "wishlistLoader",
  subTitleId1: "fetchingWishlist",
  subTitleId2: "noItemsWishlist"
}


const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addWishlistItem: (state, action) => {
      state.items = [...state.items, action.payload]
    },
    removeWishlistItem: (state, action) => {
      state.items = state.items.filter(pk => action.payload !== pk);
      state.data.wishlist_lines = state.data.wishlist_lines.filter(line => action.payload !== line.product.pk)
    },
    setWishlistItems: (state, action) => {
      state.items = action.payload
    },
    setDetailedWishlist: (state, action) => {
      state.data = action.payload
    },
  },
});

export const {
  addWishlistItem,
  removeWishlistItem,
  setWishlistItems,
  setDetailedWishlist
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
