import HeaderLink from "./HeaderLink";
// import Dashboard from "./Dashboard";
import data from './navbarData.json';

const HeaderLeft = () => {
  return (
    <nav 
      className="flex flex-1 md:justify-end h-full pl-3 md:pl-0"
    >
      <ul 
        className="
          flex items-center justify-between overflow-hidden 
          max-w-[100%] min-w-[45%]
        "
      >
        {data.map(ele => (
          <HeaderLink
            key={"header_link_" + ele.title}
            data={ele}
          />
        ))}
        {/* <Dashboard /> */}
      </ul>
    </nav>
  );
};

export default HeaderLeft;
