import { instance as apiService } from ".";
import { chatApi } from "./core";
import { setExpertInfo, setUtmInfo } from "@/store/general/generalSlice";


let userData = {}
const labels = { 'Name': 'name', "Contact Number": 'phone', 'Email': 'email' }
let expertJoined = false
let prechatTimer = false
let isMaximizedCount = 0

export async function handlePreChatSubmit(data, utm, dispatch){
    clearTimeout(prechatTimer);
    data.map(ele => {
        if(labels.hasOwnProperty(ele.label)){
            userData[ labels[ele.label] ] = ele.answer 
        }
    })
    userData['lead_source'] = 'tawk'
    prechatTimer = setTimeout(async() => {
        if(userData === null || Object.keys(userData).length === 0) return
        const url = "/api/rest/v1/chat/"  +  + (utm ? "?" + utm : "");
        const chatApiResponse = await chatApi(url,  userData);
        if (!chatApiResponse) return;
        userData = null;
        const raw_data = JSON.stringify(chatApiResponse);
        localStorage.setItem("expert_info", raw_data);
        dispatch(setExpertInfo(chatApiResponse));
        dispatch(setUtmInfo(null));
    }, 20000)
};

export async function handleAgentJoined(agent_data){
    // console.log("====agent joined====")
    if(expertJoined || userData === null || Object.keys(userData).length === 0) return
    expertJoined = true;
    try {
        const res = await apiService.post('/api/rest/v1/chat/attach-agent/', { 
            lead_data: userData, 
            agent_data,
            browser_data : {
                'user_agent' : window.navigator.userAgent,
                "os" : navigator.userAgentData?.platform || navigator.platform || 'unknown',
                "browser" : window.navigator.userAgentData?.brands ? 
                    window.navigator.userAgentData?.brands[0]: null,
            }
        });
        userData = null;
        const data = JSON.stringify(res.data);
        dispatch(setExpertInfo(data))
        localStorage.setItem("expert_info", data);
        gtag('event', 'tawk_agent_joined', { "agent_id": agent_data.id });
      }catch(err){
        userData = null;
        console.log(err.response.data)
    }
};

export function handleChatStarted(){
    chatStarted = true;
    if(window.Tawk_API.isChatMaximized() && isMaximizedCount === 0){
        isMaximizedCount = 1;
        gtag('event', 'tawk_chat_start');
    }
};

export function handleChatEnded(){
    gtag('event', 'tawk_chat_end');
};