import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  currentPage: 1,
}


const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalogData: (state, action) => {
      state.data = action.payload
    },
    setCatalogDataNext: (state, action) => {
      state.data = {
        ...state.data,
        products: state.data.products.concat(action.payload)
      }
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
});

export const { setCatalogData, setCatalogDataNext, setCurrentPage } = catalogSlice.actions;
export default catalogSlice.reducer;
