"use client";
import { useEffect } from "react";

const Gtm = () => {
  useEffect(() => {
    if (window.location.hostname.includes("ornaz.com")) {
      // add google tag manager script..
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        let userInfo = localStorage.getItem("user_info");
        let user_id = null;
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          if (userInfo.is_staff) return;
        }
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
          user_id,
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-WLK49H7");

      //add gtag script.
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        dataLayer.push(arguments);
      }
      window.gtag("js", new Date());
      window.gtag("config", "G-470TDQLKLZ");
    } else {
      window.dataLayer = [];
      window.gtag = () => {}
    }
  }, []);
  return null;
};

export default Gtm;
