"use client";

import dynamic from "next/dynamic";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "@/components/ui/dialog";
import { usePathname } from 'next/navigation'
const Container = dynamic(() => import("./Container"), { ssr: false });

let popuptimer;
let popupinterval;

const WebPopup = () => {

    const [open, setOpen] = useState(false);
    const { expertInfo } = useSelector((state) => state.general);
    const pathname = usePathname();
    const isChatPage = pathname.startsWith('/chat/');

    const onOpenChange = (val) => setOpen(val);

    const showPopUp = useCallback(async () => {
        if (expertInfo && expertInfo.lead_phone) return
        const popup_show_count = parseInt(localStorage.getItem("popup_show_count")) || 0;
        if (popup_show_count >= 1) {
            localStorage.setItem("popup_show_count", 0);
            return
        }
        localStorage.setItem("popup_show_count", popup_show_count + 1);
        popupinterval = setInterval(() => {
            clearInterval(popupinterval)
            setOpen(true);
        }, 2000)
    }, [ expertInfo ])

    useEffect(() => {
        if (!expertInfo || !expertInfo.lead_phone) {
            popuptimer = setTimeout(() => showPopUp(), 10000);
        }
        return () => {
            clearTimeout(popuptimer);
            clearInterval(popupinterval);
        }
    }, [expertInfo, showPopUp]);

    if(isChatPage) return null;

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <Container onOpenChange={onOpenChange}/>
        </Dialog>
    )
}

export default WebPopup