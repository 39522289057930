"use client";
import { useSelector } from "react-redux";

const Label = () => {
    const user = useSelector((state) => state.general.user);
    const isSubbed = user?.is_newsletter_subscribed;
    return (
        <li className="capitalize my-[5px] w-full">
            <p className="text-[12px] xl:text-sm  text-font-primaryLight">
                {" "}
                {isSubbed ? "You are already subscribed." : "Send me ORNAZ latest designs, updates and offers."}
                {" "}
            </p>
        </li>
    )
}

export default Label