"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Sheet, SheetTrigger } from "@/components/ui/sheet";
import { SheetContent } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import ClientLoader from "@/components/CustomUI/modules/Loaders/clientLoader";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import Count from "../Count";
import { store } from "@/store";
import { usePathname } from 'next/navigation';
import { loggingAPI } from "@/clientSideApi/core";
const WishRegister = dynamic(() => import("@/components/routes/WishRegister"), { ssr: false });

let prevPath;

const WishlistPopup = () => {
  const loaderID = store.getState().wishlist.loaderID;
  const subTitleId1 = store.getState().wishlist.subTitleId1;
  const subTitleId2 = store.getState().wishlist.subTitleId2;
  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const isWishlist = pathname === '/wishlist/';

  const onOpenChange = async (val) => {
    if(val){
      await loggingAPI({ url: "wishlist-popup" })
    }
    if (!isWishlist) {
      setOpen(val);
    }
  }

  useEffect(() => {
    if (prevPath && prevPath !== pathname) {
      setOpen(false)
    };
    prevPath = pathname;
  }, [pathname])

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger asChild aria-controls="">
        <Button
          aria-label="wishlist"
          variant="secondary"
          className="
              bg-white hover:bg-white
              px-0 py-0 mx-3 relative
          "
          id="wishlistPopupTriggerBtn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={26}
            viewBox="0 0 22 19"
            fill="none"
            className="
            desktop:min-w-[30px] desktop:min-h-[30px]
            header-svg
          "
          >
            <mask
              id="mask0_1480_11092"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={22}
              height={22}
            >
              <g clipPath="url(#clip0_1480_11092)">
                <path
                  d="
                    M3.32106 9.54841L11.4901 17.7174L19.6591 9.54841C20.1087 
                    9.09877 20.4654 8.56498 20.7087 7.9775C20.952 7.39003 
                    21.0773 6.76037 21.0773 6.12449C21.0773 5.48861 20.952
                    4.85896 20.7087 4.27148C20.4654 3.68401 20.1087 3.15021 
                    19.6591 2.70058C19.2094 2.25095 18.6756 1.89428 18.0882 
                    1.65093C17.5007 1.40759 16.871 1.28235 16.2352 1.28235C14.9509 
                    1.28235 13.7193 1.7925 12.8112 2.70058L11.4901 4.02175L10.1689 
                    2.70058C9.26081 1.7925 8.02919 1.28235 6.74498 1.28235C5.46076 
                    1.28235 4.22914 1.7925 3.32106 2.70058C2.41299 3.60866 1.90283 
                    4.84028 1.90283 6.12449C1.90283 7.40871 2.41299 8.64033 3.32106 
                    9.54841Z
                  "
                  stroke="#393939"
                  strokeWidth="0.913043"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </mask>
            <g mask="url(#mask0_1480_11092)">
              <rect
                width={21}
                height={18}
                transform="translate(0.990234 0.5)"
                fill="#393939"
              />
            </g>
            <defs>
              <clipPath id="clip0_1480_11092">
                <rect
                  width={21}
                  height="17.3478"
                  fill="white"
                  transform="translate(0.990234 0.82605)"
                />
              </clipPath>
              <clipPath id="clip1_1480_11092">
                <rect
                  width={21}
                  height="17.3478"
                  fill="white"
                  transform="translate(0.990234 0.82605)"
                />
              </clipPath>
            </defs>
          </svg>
          <Count id={"wishlistCount"} />
        </Button>
      </SheetTrigger>

      <SheetContent
        className="
          min-w-[45vw] xl:min-w-[40vw] bg-white text-xs md:text-sm 
          pl-3 pr-[4px] z-50 overflow-x-hidden
          vertical-scrollbar
          
        "
      >
        <div className="flex w-full items-center justify-center mb-6 mt-2">
          <DropdownMenuSeparator className="bg-[#E5E5E5] h-[1px] flex-[0.4]" />
          <p className="px-3 text-xl tracking-wider drop-shadow-md">
            Your Wishlist
          </p>
          <DropdownMenuSeparator className="bg-[#E5E5E5] h-[1px] flex-[0.4]" />
        </div>
        <ClientLoader
          id={loaderID}
          mainTitle={"Your Wishlist"}
          subTitle={{
            id1: subTitleId1,
            text_1: "Hold on!\nFetching your wishlist",
            id2: subTitleId2,
            text_2: "Uh Oh!\nYour Wishlist Seems to be Empty!",
          }}
          link={{
            title: "Start Shopping",
            url: "/jewellery/earrings/?collections=gift",
          }}
        />
        <WishRegister />
      </SheetContent>
    </Sheet>
  );
};

export default WishlistPopup;
