import { createSlice } from "@reduxjs/toolkit";
import { getInitialCategoryData } from '@/lib/server-utils';

const initialState = {
  data: null,
  size: null,
  initialChoices: null
};


const pdpSlice = createSlice({
  name: "pdp",
  initialState,
  reducers: {
    setPdpData: (state, action) => {
      state.data = action.payload;
      state.initialChoices = getInitialCategoryData({
        basic_details : action.payload.basic_details,
        metal_color : action.payload.output?.metals?.color || ""
      })
    },
    setRingSize: (state, action) => {
      state.size = action.payload;
    },
    setCustomData: (state, action) => {
      const {choicesData, ...rest} = action.payload;
      if(choicesData.metal_carat?.length > 0){
        choicesData['metal_color'] = choicesData.metal_carat.split("_")[1];
      }
      state.initialChoices = choicesData;
      state.data = {
        ...state.data,
        ...rest
      }
    },
  },
});

export const { setPdpData, setRingSize, setCustomData } = pdpSlice.actions;
export default pdpSlice.reducer;
