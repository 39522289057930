"use client";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Script from "next/script";
import {
  handlePreChatSubmit,
  handleAgentJoined,
  handleChatStarted,
  handleChatEnded
} from "@/clientSideApi/tawk";
import { getUserDetails } from "@/lib/user";

const Tawk = () => {

  let interval = useRef();
  const dispatch = useDispatch();
  const utm = useSelector((state) => state.general.utm);
  const user = useSelector((state) => state.general.user);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      if (window.Tawk_API !== undefined) {
        clearInterval(interval.current);
        setLoaded(true);
      }
    }, 1000)
    return () => clearInterval(interval.current);
  }, [utm, dispatch, interval]);


  useEffect(() => {
    if (loaded) {
      window.Tawk_API.onPrechatSubmit = (data) => {
        handlePreChatSubmit(data, utm, dispatch)
      };
      window.Tawk_API.onAgentJoinChat = handleAgentJoined;
      window.Tawk_API.onChatStarted = handleChatStarted;
      window.Tawk_API.onChatEnded = handleChatEnded;
      const userDetails = getUserDetails(user);
      if(userDetails.email){
        try {
          window.Tawk_API.setAttributes(userDetails, function(error){
            console.warn(error, "<===tawk phone prefill issue")
          });          
        } catch (error) {
          console.log("Tawk api not working")
        }
      }
      if(window.gtag){
        gtag('event', 'tawk_view');
      }
    }
  }, [loaded, utm, user, dispatch])


  return (
    <Script
      src="https://embed.tawk.to/57a0eed614e2f0af26fdd579/default"
      strategy="afterInteractive"
    />
  );
};

export default Tawk;
