import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  emailIsValid: false,
  addressIsValid: false,
  notify: false,
  content: "",
  loaderID: "cartLoader",
  subTitleId1: "fetchingCart",
  subTitleId2: "noItemsCart"
}


const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartData: (state, action) => {
      state.data = action.payload;
      state.addressIsValid = !action.payload.shipping_address === false;
    },
    setEmailValidity: (state, action) => {
      state.emailIsValid = action.payload
    },
    setNotify: (state, action) => {
      state.notify = action.payload
    },
    setContent: (state, action) => {
      state.content = action.payload
    },
    setAddressValidity: (state, action) => {
      state.addressIsValid = action.payload
    },
    flushCart: (state,) => {
      state.data = null;
      state.emailIsValid = false;
      state.notify = false;
      state.addressIsValid = false;
      state.content = "";
    },
  },
});

export const { 
  setCartData, setEmailValidity, setNotify,
  setContent, setAddressValidity, flushCart
} = cartSlice.actions;
export default cartSlice.reducer;
