import Image from "next/image";
import CustomLink from "@/components/CustomUI/modules/CustomLink";
import PriceConvert from "@/components/CustomUI/modules/PriceConvert";
import { interBold } from "@/app/jewellery/font";

const Navbar = ({ data, visible, handleClick }) => {
  return (
    <nav
      className="
        flex bg-white justify-center pt-4
      "
    >
      <ul
        className="
          flex justify-center gap-3 md:gap-5 
          lg:gap-8 xl:gap-10 pb-5 flex-wrap 
          xl:flex-nowrap
        "
      >
        {data.map((ele) => (
          <li key={ele.img.alt} className={ele.extraClasses}>
            <CustomLink
              href={ele.to}
              handleClick={handleClick}
              isNative={ele.nativeLink}
              classes={
                "block " +
                (ele.isPotrait
                  ? "w-[220px] desktop:w-[300px]"
                  : `w-[130px] h-[130px] md:w-[160px] md:h-[160px] 
                    lg:w-[200px] lg:h-[200px] xl:w-[220px] xl:h-[220px] 
                    desktop:w-[300px] desktop:h-[300px]`)
              }
            >
              {visible && (
                <Image
                  src={ele.img.src}
                  alt={ele.img.alt}
                  width={ele.img.width}
                  height={ele.img.height}
                  loading="eager"
                  priority={ele.img.priority}
                  className="w-full h-full"
                />
              )}
            </CustomLink>

            <h2
              className={`
                text-xs md:text-sm lg:text-base font-semibold 
                xl:font-bold xl:text xl:text-lg	desktop:text-xl
                text-font-navHeader pt-5 pb-3 
                mt-1 capitalize ${interBold.className}
              `}
            >
              {ele.heading}
            </h2>

            {ele.hasInnerNav && (
              <nav
                className={`w-[130px] md:w-[160px] 
                  lg:w-[${ele.img.width}px]
                `}
              >
                <ul>
                  {ele.innerNav.map((linkItem, i) => (
                    <li
                      key={linkItem.title + i}
                      className={
                        "truncate " +
                        linkItem.extraClasses +
                        (i > 0 ? "my-2" : "")
                      }
                    >
                      {linkItem.isPrice ? (
                        <CustomLink
                          href={linkItem.to}
                          handleClick={handleClick}
                          isNative={linkItem.nativeLink}
                          classes="
                          text-font-navLink text-xs 
                          lg:text-sm xl:text-base desktop:text-lg
                          font-normal lowercase
                          hover:underline max-w-full
                        "
                        >
                          {linkItem.title}{" "}
                          <span className="uppercase">
                            <PriceConvert amount={linkItem.price} />
                          </span>
                        </CustomLink>
                      ) : (
                        <CustomLink
                          href={linkItem.to}
                          handleClick={handleClick}
                          isNative={linkItem.nativeLink}
                          classes="
                          text-font-navLink text-xs 
                          lg:text-[14px] xl:text-[16px] 
                          font-normal lowercase
                          hover:underline max-w-full
                        "
                        >
                          {linkItem.title}
                        </CustomLink>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
