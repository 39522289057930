"use client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetUserQuery } from "@/store/general/generalApiSlice";
import { handleUserData } from "@/lib/user";
import { tryGoogleOneTap } from "@/components/CustomUI/SocialAuth/GoogleOneTap";
import { getInitialData } from "@/lib/user";

const AutoLogin = () => {
  const { data } = useGetUserQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    getInitialData(dispatch);
  }, [ dispatch ])

  useEffect(() => {
    if (data) {
      if (data.error_msg) {
        tryGoogleOneTap(dispatch);
      } else {
        handleUserData(data, dispatch);
        gtag("event", "login", {
          'method': 'auto-login',
          "user_id": data.user_id,
          'email': data.email,
          'phone': data.phone,
          'fullName': data.full_name
        });
      }
    }
  }, [data, dispatch]);
  return null;
};

export default AutoLogin;
