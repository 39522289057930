import { oneTapAPI } from "@/clientSideApi/account";
import { handleUserData } from "@/lib/user";
import { toast } from 'react-toastify';

const callback = async (data, dispatch) => {
  const response = await oneTapAPI(data);
  if(!response || !response.token) return;
  handleUserData(response, dispatch);
  toast.success('Signed in Successfully', {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  gtag("event", "login", {
    'method': 'google-one-tap',
    "user_id": response.user_id,
    'email': response.email,
    'phone': response.phone,
    'fullName': response.full_name
  });
};

const tryGoogleOneTap = (dispatch) => {
  if(!window.google) return;
  try{
    window.google.accounts.id.initialize({
      client_id: process.env.GOOGLE_APP_CLIENT_ID,
      callback: (response) => callback(response, dispatch),
      auto_select: false,
      cancel_on_tap_outside: false,
      context: "use", //["signin", "signup", "use"],
      ...{},
    });
    window.google.accounts.id.prompt()
  }catch(e){
    console.warn("OneTap Failed ", e)
  }
};

export { tryGoogleOneTap };
