"use client";

import dynamic from "next/dynamic";

import { useState, useCallback, useRef } from "react";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { searchAPI } from "@/clientSideApi/core";
const Container = dynamic(() => import("./Container"), { ssr: false });

const Search = () => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef();

  const onOpenChange = useCallback((val) => {
    setVisible(val);
  }, []);

  const updateData = useCallback((val) => {
    if (val.length < 3) {
      setSearchData([]);
      return;
    }
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(async () => {
      const apiResponse = await searchAPI(val);
      if (!apiResponse || !apiResponse.results) return;
      setSearchData(apiResponse.results);
      setLoading(false);
    }, 250);
  },
    [timerRef]
  );

  const handleChangeText = useCallback((e) => {
    const val = e.target.value;
    setSearch(val);
    updateData(val);
  },
    [updateData]
  );

  return (
    <Popover
      open={visible}
      onOpenChange={onOpenChange}
      className="w-full h-full "
    >
      <PopoverTrigger
        asChild
        id=""
      >
        <Button
          aria-label="search"
          variant="secondary"
          className="
              bg-white hover:bg-white
              px-0 py-0 mx-3 relative flex
              items-center justify-center cursor-pointer 
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={26}
            viewBox="0 0 21 21"
            fill="none"
            className="header-svg desktop:min-w-[30px] desktop:min-h-[30px]"
          >
            <mask
              id="mask0_1480_11106"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={0}
              width={21}
              height={21}
            >
              <g clipPath="url(#clip0_1480_11106)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="
                    M9.39005 0.0447375C6.06719 0.217118 3.19957 2.28474 
                    2.00814 5.36759C1.76596 6.00297 1.60283 6.66573 1.52243 
                    7.34093C1.46719 7.77712 1.47576 8.8314 1.53957 9.30474C2.01672 
                    12.8676 4.69291 15.6752 8.25672 16.3533C9.412 16.5585 10.5967 
                    16.5293 11.7405 16.2676C12.6898 16.0311 13.5941 15.6413 
                    14.4177 15.1133C14.6558 14.9552 14.8005 14.8781 14.81 
                    14.9038C14.8329 14.9628 20.2148 20.2666 20.3005 20.3143C20.374 
                    20.3426 20.4522 20.3568 20.531 20.3562C20.6596 20.3571 20.7091 
                    20.339 20.8053 20.2543C20.8764 20.1971 20.9293 20.1204 20.9574 
                    20.0336C20.9855 19.9468 20.9876 19.8537 20.9634 19.7657C20.932 
                    19.6638 20.4253 19.1476 18.2091 16.9609L15.492 14.2809L15.8548 
                    13.9038C16.4519 13.2899 16.9436 12.5816 17.31 11.8076C17.8149 
                    10.7846 18.0893 9.66335 18.1139 8.52283C18.21 5.67712 16.8281 
                    3.02664 14.4072 1.4095C14.0205 1.1514 13.1529 0.715214 12.6786 
                    0.540928C11.7234 0.189499 10.4129 -0.00764342 9.39005 
                    0.0447375ZM10.7443 1.04188C12.7291 1.30188 14.5567 2.35426 
                    15.7072 3.89902C16.4681 4.92188 16.9215 5.99521 17.112 
                    7.22664C17.192 7.74759 17.192 8.76569 17.112 9.29045C16.9525 
                    10.3479 16.5655 11.3581 15.9777 12.2514C15.4021 13.1161 14.6508 
                    13.8498 13.7727 14.4048C12.8946 14.9597 11.9095 15.3235 10.8815 
                    15.4724C8.19767 15.8657 5.50053 14.7666 3.88148 12.619C3.18384 
                    11.7001 2.71681 10.6272 2.51957 9.49045C2.43005 8.9895 2.39957 
                    8.0314 2.45576 7.52093C2.82814 4.14569 5.45005 1.48569 8.84053 
                    1.04188C9.47362 0.979972 10.1112 0.979972 10.7443 1.04188Z
                    "
                  fill="black"
                />
              </g>
            </mask>
            <g mask="url(#mask0_1480_11106)">
              <rect
                width={20}
                height={21}
                transform="translate(0.990234)"
                fill="#393939"
              />
            </g>
            <defs>
              <clipPath id="clip0_1480_11106">
                <rect
                  width={20}
                  height="20.9524"
                  fill="white"
                  transform="translate(0.990234 0.0238037)"
                />
              </clipPath>
            </defs>
          </svg>
        </Button>
      </PopoverTrigger>
      <Container
        data={searchData}
        search={search}
        loading={loading}
        handleChangeText={handleChangeText}
        onOpenChange={onOpenChange}
      />
    </Popover>
  );
};

export default Search;
