import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@/lib/utils";



export const generalApiSlice = createApi({
  baseQuery: axiosBaseQuery,
  reducerPath: "api1",
  tagTypes: ["General"],
  endpoints: (builder) => ({
    addNewsLetter: builder.mutation({
      query: (initialPost) => ({
        url: "/api/rest/v1/newsletter/",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: ["General"],
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "/api/rest/v1/account/logout/",
        method: "POST",
      }),
      invalidatesTags: ["General"],
    }),
    getUser: builder.query({
      query: () => "/api/rest/v1/account/try-auto-login/"
    }),
  }),
});

export const { 
  useAddNewsLetterMutation, 
  useLogoutUserMutation,
  useGetUserQuery 
} = generalApiSlice;
